function equalHeight(a) {
    tallest = 0, a.each(function() {
        thisHeight = $(this).height(), thisHeight > tallest && (tallest = thisHeight)
    }), a.each(function() {
        $(this).height(tallest)
    })
}
$(document).ready( function() {
    $('#bannerDestaque').carousel({
		interval:   5500
	});
	 $('#carousel-reviews').carousel({
		interval:   false
	});


	$('#bannerDestaque').on('slide.bs.carousel', function(e) {
        var from = $('#bannerDestaque .nav li.active').index();
        //var next = $(e.relatedTarget);
        var to =  $(e.relatedTarget).index();

      	$('#bannerDestaque .nav li').removeClass('active').eq(to).addClass('active');
    });
    equalHeight($("#infraesturtura-section .caption"));
});